// import GluttonRecorder from './glutton-saas-cmd2';
import GluttonRecorder from './glutton-record';
// import { Base64 } from 'js-base64';
// import { sha256 } from 'js-sha256';
import { ModelWidgetInterface, PolicyModel, WidgetHelped, AjaxResponse } from "@my-types";
import { Envs, Ajax, Apis, Utils } from '@common';


// const GLUTTON_SERVER_HOST = 'glutton-demo.ebaocloud.com.cn';
// const GLUTTON_MAX_SLEEP = 1000;//最大等待秒数

type GluttonTokenType = {
	firstToken?: string;
	serverHost?: string;
}

const initGluttonRecorder = (options: {
	vendorCode: string;
	productCode: string;
	productVersion: string;
}) => {
	if (Envs.isDesk()) {
		// 桌面不需要
		return Promise.resolve();
	}
	//开关没开不启用
	if (!Utils.isTrue(Envs.getEnvValue('GLUTTON_ALLOWED'))) {
		return Promise.resolve();
	}
	const queryString = Utils.fromQueryString();
	const { delegated = '' } = queryString;
	//判断租户,缓存中可能还没有，从账号上截取租户code
	const { tenantCode = delegated?.substr(0, delegated?.indexOf('.')) } = Envs.findAccount();
	return new Promise<void>((resolve, reject) => {
		Ajax.post(
			Apis.GLUTTON_ASK_TOKEN, { ...options, tenantCode }
		).then((data: AjaxResponse) => {
			const { firstToken, serverHost } = (data.body || {}).body || {} as GluttonTokenType;
			if (!Utils.isBlank(firstToken) && !Utils.isBlank(serverHost)) {
				//@ts-ignore
				resolve(new GluttonRecorder({
					firstToken: firstToken,
					serverHost: serverHost,
					disableLogs: false,
					dataAutoPlay: false,
					recordOptions: {
						iconFonts: ["Font-Awesome5.14"]
					}
				}));
			} else {
				resolve();
			}
		}).catch(error => {
			resolve();
		});
	})
}


let started = false;



const startRecord = async (delay?: number): Promise<void> => {
	if (started) {
		console.log("GluttonRecorder Started Already!");
	}
	started = true
	console.log("GluttonRecorder Start Recording!");
	let start = new Date().getTime();
	try {
		if (window.gluttonRecorder) {
			if (delay && delay > 0) {
				setTimeout(() => window.gluttonRecorder.startRecord(), delay);
			} else {
				window.gluttonRecorder.startRecord();
			}
		}
	} catch (e) {
		console.error(e);
	} finally {
		let end = new Date().getTime();
		console.log('start const time', end, start, end - start);
	}
	return Promise.resolve();
}

const stopRecord = async (): Promise<void> => {
	if (!started) {
		console.log("GluttonRecorder No Started!");
	}
	console.log("GluttonRecorder End Recording!");
	let start = new Date().getTime();
	try {
		if (window.gluttonRecorder) {
			await window.gluttonRecorder.stopRecord();
		}
	} catch (e) {
		console.error(e);
	} finally {
		let end = new Date().getTime();
		console.log('stop cost time', end, start, end - start);
	}
	return Promise.resolve();
}

// const sleep = (time: number): Promise<void> => {
// 	return new Promise((resolve) => setTimeout(resolve, time));
// }

const putGluttonContext = (key: string, value: any) => {
	//@ts-ignore
	let gluttonContext = window.GluttonContext || {};
	gluttonContext[key] = value;
	//@ts-ignore
	window.GluttonContext = gluttonContext;
}

const updateOrderNoAndPolicyNo = (invoker: WidgetHelped & ModelWidgetInterface,) => {
	//投保过程中，orderNo和policyNo是在中间步骤生产，需要在生成后塞进GluttonContext
	const policy = invoker.getRootModel();
	//@ts-ignore
	let gluttonContext = window.GluttonContext || {};
	gluttonContext.orderNo = policy.quotationNo;
	gluttonContext.policyNo = policy.policyNo;
	//@ts-ignore
	window.GluttonContext = gluttonContext;
}

enum GluttonConstans {
	PRODUCT_NAME = 'productName',
	TRACE_NO = 'traceNo',
	POLICY_NO = 'policyNo',
	ORDER_NO = 'orderNo',
	QUOTE_NO = 'quoteNo',
	PAY_NO = 'payNo',
	PAY_ORDER_NO = 'payOrderNo',
	POLICY_HOLDER_NAME = 'policyHolderName',
	POLICY_HOLDER_ID_NO = 'policyHolderIdNo',
	POLICY_HOLDER_MOBILE = 'policyHolderMobile',
	ALL_STEPS = 'allSteps',
	CURRENT_STEP = 'currentStep',
	SOURCE = 'source',
	VERDOR_NAME = 'uniqueNo1',
	VERSION = 'version'
}

export { startRecord, stopRecord, putGluttonContext, GluttonConstans, updateOrderNoAndPolicyNo, initGluttonRecorder }

