//都使用新的sdk
import * as DefaultGluttonRecorder from './glutton-saas-cmd2.rr.min';
import * as MockGluttonRecorder from './MOCK/glutton-saas-cmd2.rr.min';
import Envs from '../envs';

const getGluttonRecorder = (): any => {
	//测试环境使用特殊的MOCK包
	if (Envs.getEnvValue("ENV_NAME") === 'MOCK') {
		return MockGluttonRecorder.default;
	} else {
		return DefaultGluttonRecorder.default;
	}
}


const GluttonRecorder = getGluttonRecorder();

export default GluttonRecorder;
