type AuthCodes = {
	readonly DESK_QRCODE: string;
	readonly DESK_PRODUCT: string;
	readonly DESK_PRODUCT_HOT: string;
	readonly DESK_PRODUCT_TAG: string;
	readonly DESK_PRODUCT_TAG_JOINT: string;
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// readonly DESK_PRODUCT_UI: string;
	readonly DESK_AGREEMENT_APPLY: string;
	readonly DESK_AGREEMENT_APPROVE: string;
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// readonly DESK_EXPLORE: string;
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// readonly DESK_COMMISSION: string;
	readonly DESK_ORGAN_LIST: string;
	readonly DESK_ORGAN_UPLOAD: string;
	readonly DESK_ACCOUNT_LIST: string;
	readonly DESK_ACCOUNT_UPLOAD: string;
	readonly DESK_SERVICE_LIST_VIEW: string;
	readonly DESK_SERVICE_PROBATION_LIST: string;
	readonly DESK_APIKEYS: string;

	readonly DESK_POLICY_ENTRY: string;
	readonly DESK_POLICY_QUERY: string;
	readonly DESK_POLICY_URL_QUERY: string;
	readonly DESK_POLICY_OSS_URL_CHANGE: string;
	readonly DESK_ADVICE_QUERY: string;
	readonly DESK_POLICY_DOWNLOAD: string;
	readonly DESK_SERVICE_FEE_DOWNLOAD: string;
	readonly DESK_CUSTOMER_QUERY: string;
	readonly DESK_POLICY_LOG_QUERY: string;
	readonly DESK_ASYNC_POLICY: string;
	readonly DESK_DASHBOARD: string;
	readonly DESK_SMS: string;
	readonly DESK_AGENT_SYNC_QUERY: string;
	readonly DESK_GROUP_POLICY: string;
	readonly DESK_POLICY_PROBLEM: string;
	readonly DESK_STATEMENT: string;
	readonly DESK_AUDIT_LOG_QUERY: string;

	readonly DESK_IDENTITY_CERTIFICATION_RECORD: string;

	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// readonly DESK_COURSE: string;
	// readonly DESK_ACCOUNT_COURSE: string;
	// readonly DESK_QUIZ: string;
	// readonly DESK_ACCOUNT_QUIZ: string;

	readonly MOBILE_ACCESS: string;
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190318 Brad Wu */
	// readonly MOBILE_WALLET: string;
	// readonly MOBILE_STORE: string;
	readonly MOBILE_HOME: string;
	readonly MOBILE_HOME_SHORTCUTS: string;
	readonly MOBILE_EXPLORE: string;
	readonly MOBILE_ME: string;
	readonly MOBILE_PRODUCT_CATEGORY: string;
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190318 Brad Wu */
	// readonly MOBILE_CLAIM_CASE: string;
	// readonly MOBILE_CLAIM_QUERY: string;
	readonly MOBILE_MY_POLICY: string;
	readonly MOBILE_MY_QUOTE: string;
	readonly MOBILE_MY_ADVICE: string;
	readonly MOBILE_POLICY_QUERY: string;
	readonly MOBILE_MY_CUSTOMER: string;
	readonly MOBILE_MY_FAV: string;
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190318 Brad Wu */
	// readonly MOBILE_ONLINE_TRAINING: string;
	readonly MOBILE_MY_SETTING: string;
	readonly MOBILE_CHANGE_PWD: string;
	readonly MOBILE_CHANGE_THEME: string;
	readonly MOBILE_ME_DASHBOARD: string;
	/**保司业务 */
	readonly INSURANCE_CO_HQ_ORDER_CHECK: string;
	/**商城配置 */
	readonly MALL_ACCESS: string;
	readonly MALL_HOME: string;
	readonly MALL_ME: string;
	readonly MALL_APPOINT: string;
	readonly MALL_CHANGE_PWD: string;
};
// 权限代码
export default {
	DESK_PRODUCT: 'desk-product-maintain',
	DESK_QRCODE: 'desk-qrcode',
	DESK_PRODUCT_HOT: 'desk-product-hot-maintain',
	DESK_PRODUCT_TAG: 'desk-product-tag-maintain',
	DESK_PRODUCT_TAG_JOINT: 'desk-product-tag-joint-maintain',
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// DESK_PRODUCT_UI: 'desk-product-ui-maintain',
	DESK_AGREEMENT_APPLY: 'desk-agreement-apply-maintain',
	DESK_AGREEMENT_APPROVE: 'desk-agreement-approve-maintain',
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// DESK_EXPLORE: 'desk-explore-maintain',
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// DESK_COMMISSION: 'desk-commission-maintain',
	DESK_ORGAN_LIST: 'desk-organ-list',
	DESK_ORGAN_UPLOAD: 'desk-organ-upload-sync',
	DESK_ACCOUNT_LIST: 'desk-account-list',
	DESK_ACCOUNT_UPLOAD: 'desk-account-upload-sync',
	DESK_SERVICE_LIST_VIEW: 'desk-service-list-view',
	DESK_SERVICE_PROBATION_LIST: 'desk-service-probation-list',
	DESK_APIKEYS: 'desk-account-apikey',

	DESK_POLICY_ENTRY: 'desk-policy-entry',
	DESK_POLICY_QUERY: 'desk-policy-query',
	DESK_POLICY_URL_QUERY: 'desk-policy-url-query',
	DESK_POLICY_OSS_URL_CHANGE: 'desk-policy-oss-url-change',

	DESK_ADVICE_QUERY: 'desk-advise-query',
	DESK_POLICY_DOWNLOAD: 'desk-policy-download',
	DESK_SERVICE_FEE_DOWNLOAD: 'desk-service-fee-download',
	DESK_CUSTOMER_QUERY: 'desk-customer-query',
	DESK_POLICY_LOG_QUERY: 'desk-policy-log-query',
	DESK_ASYNC_POLICY: 'desk-async-policy',
	DESK_DASHBOARD: 'desk-dashboard',
	DESK_SMS: 'desk-sms',
	DESK_AGENT_SYNC_QUERY: 'desk-agent-sync-query',
	DESK_GROUP_POLICY: 'desk-group-policy',
	DESK_POLICY_PROBLEM: 'desk-policy-problem',
	DESK_STATEMENT: 'desk-statement-maintain',
	DESK_AUDIT_LOG_QUERY: 'desk-audit-log-query',
	DESK_IDENTITY_CERTIFICATION_RECORD: 'desk-identity-certification-record',

	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190306 Brad Wu */
	// DESK_COURSE: 'desk-course-maintain',
	// DESK_ACCOUNT_COURSE: 'desk-account-course-maintain',
	// DESK_QUIZ: 'desk-quiz-maintain',
	// DESK_ACCOUNT_QUIZ: 'desk-account-quiz-maintain',
	//保险公司业务，横琴网销订单审核
	INSURANCE_CO_HQ_ORDER_CHECK: 'insurance-co-hq-order-check',
	MOBILE_ACCESS: 'mobile-access',
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190318 Brad Wu */
	// MOBILE_WALLET: 'mobile-my-wallet',
	// MOBILE_STORE: 'mobile-my-store',
	MOBILE_HOME: 'mobile-home',
	MOBILE_HOME_SHORTCUTS: 'mobile-home-shortcuts',
	MOBILE_EXPLORE: 'mobile-explore',
	MOBILE_ME: 'mobile-me',
	MOBILE_PRODUCT_CATEGORY: 'mobile-product-category',
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190318 Brad Wu */
	// MOBILE_CLAIM_CASE: 'mobile-claim-case',
	// MOBILE_CLAIM_QUERY: 'mobile-claim-query',
	MOBILE_MY_POLICY: 'mobile-my-policy',
	MOBILE_MY_QUOTE: 'mobile-my-quote',
	MOBILE_MY_ADVICE: 'mobile-my-advice',
	MOBILE_POLICY_QUERY: 'mobile-policy-query',
	MOBILE_MY_CUSTOMER: 'mobile-my-customer',
	MOBILE_MY_FAV: 'mobile-my-fav',
	/** SUSPEND 此功能暂停开放, 目前无使用租户 20190318 Brad Wu */
	// MOBILE_ONLINE_TRAINING: 'mobile-online-training',
	MOBILE_MY_SETTING: 'mobile-my-setting',
	MOBILE_CHANGE_PWD: 'mobile-change-pwd',
	MOBILE_CHANGE_THEME: 'mobile-change-theme',
	MOBILE_ME_DASHBOARD: 'mobile-me-dashboard',

	MALL_ACCESS: 'mall-access',
	MALL_HOME: 'mall-home',
	MALL_ME: 'mall-me',
	MALL_APPOINT: 'mall-appoint',
	MALL_CHANGE_PWD: 'mall-change-pwd',
} as AuthCodes;
